import { FormCache } from "../form-cache";
import { StepHelper } from "./stepHelper";
import { FormStorage } from "../form-storage";
import { FormConstants } from "../models";
import { getDefaultValue } from "./elementHelper";
import { equals, isNullOrEmpty } from "./utils";
/**
 * Function to initialize FormState object
 * @param formContainer A form container
 * @returns An object of FormState
 */
export function initFormState(formContainer, currentPageUrl, history) {
    var _a;
    const formStorage = new FormStorage(formContainer);
    const formData = formStorage.loadFormDataFromStorage();
    const formCache = new FormCache();
    const stepHelper = new StepHelper(formContainer);
    let formSubmissions = [];
    let formValidationResults = [];
    let stepDependencies = [];
    let elementDependencies = [];
    (_a = formContainer.steps) === null || _a === void 0 ? void 0 : _a.forEach(s => {
        s.elements.forEach(e => {
            if (e.key != s.formStep.key) {
                //init form validation
                formValidationResults = formValidationResults.concat({ elementKey: e.key, result: { valid: true, message: "" } });
                //init form submission
                formSubmissions = formSubmissions.concat({ elementKey: e.key, value: getDefaultValue(e) });
                //init form elements dependencies
                elementDependencies = elementDependencies.concat({ elementKey: e.key, isSatisfied: true });
            }
        });
        stepDependencies = stepDependencies.concat({ elementKey: s.formStep.key, isSatisfied: false });
    });
    //binding the elements with saved data between Next/Prev navigation
    if (formData.length > 0) {
        formSubmissions = formSubmissions.map(s => {
            let savedData = formData.find(d => equals(d.elementKey, s.elementKey));
            return savedData ? savedData : s;
        });
    }
    let stepIndexCached = formCache.get(FormConstants.FormCurrentStep + formContainer.key);
    let currentStepIndex = isNullOrEmpty(stepIndexCached)
        ? stepHelper.getCurrentStepIndex(currentPageUrl)
        : parseInt(stepIndexCached);
    return {
        isReset: false,
        focusOn: "",
        dependencyInactiveElements: [],
        currentStepIndex,
        formSubmissions,
        formValidationResults,
        stepDependencies,
        formContainer,
        history,
        elementDependencies
    };
}
