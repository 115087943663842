import { equals } from "./utils";
export function getConcatString(srcObject, seperator) {
    return (srcObject instanceof Array) ? srcObject.join(seperator) : srcObject;
}
export function getValueOfDependeeElement(condition, formSubmissions, elementDependencies) {
    var _a;
    var dependeeFieldValue = (_a = formSubmissions.filter(s => equals(s.elementKey, condition.field))[0]) === null || _a === void 0 ? void 0 : _a.value;
    const dependeeElement = elementDependencies.find(e => e.elementKey === condition.field);
    if (dependeeElement && !dependeeElement.isSatisfied) {
        // if element is in the inactive list, consider its value as undefined
        dependeeFieldValue = undefined;
    }
    return dependeeFieldValue;
}
